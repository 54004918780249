import { Button } from '@chakra-ui/button';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/modal';
import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import _ from 'lodash';

import SearchSelectMultipleUsers, {
  SelectedUserMap,
} from '../../../components/SearchSelectMultipleUsers';
import { useMutation } from 'react-query';
import { addUsersToGroupMessageThread } from '../../../apis/group-message-threads.api';
import { ValueCallback } from '../../../types/common.type';
import { MessageThreadMember } from '../../../models/MessageThreadMember.model';

interface AddMemberDialogProps extends Omit<ModalProps, 'children'> {
  existingMembers: MessageThreadMember[];
  messageThreadId: number;
  onMembersAdded: ValueCallback<MessageThreadMember[]>;
}

const AddMemberDialog: React.FC<AddMemberDialogProps> = ({
  messageThreadId,
  existingMembers,
  onMembersAdded,
  ...props
}) => {
  const [usersToAdd, setUsersToAdd] = useState<SelectedUserMap>({});

  const {
    mutate: addMembers,
    data: membersAdded,
    isSuccess: hasAddedMembers,
    isLoading: isAddingMembers,
  } = useMutation('addMembers', (userIdsToAdd: number[]) =>
    addUsersToGroupMessageThread(messageThreadId, userIdsToAdd),
  );

  useEffect(() => {
    if (hasAddedMembers && membersAdded) {
      onMembersAdded(membersAdded);
      setUsersToAdd({});
    }
    // (for onMembersAdded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAddedMembers, membersAdded]);

  const handleOnAddMembersClick = () => {
    addMembers(_.map(usersToAdd, user => user.id));
  };

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent mx='4' minH='40%'>
        <ModalHeader>Add Members to group</ModalHeader>
        <ModalBody>
          <SearchSelectMultipleUsers
            selectedUsers={usersToAdd}
            setSelectedUsers={setUsersToAdd}
            shouldRenderUser={user =>
              !_.find(existingMembers, member => member.user?.id === user.id)
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isAddingMembers}
            disabled={_.isEmpty(usersToAdd)}
            colorScheme='blue'
            leftIcon={<MdAdd />}
            onClick={handleOnAddMembersClick}
          >
            Add Selected Users
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberDialog;
