import _ from 'lodash';

import {
  emitCallIceCandidate,
  BaseSignallingDto,
  emitCallOffer,
  onCallAnswer,
  onCallOffer,
  onCallIceCandidate,
  emitCallAnswer,
  CallAnswerHandler,
  CallOfferHandler,
  CallIceCandidateHandler,
  unsubscribeCallAnswer,
  unsubscribeCallIceCandidate,
  unsubscribeCallOffer,
} from 'src/apis/socket.io/call-signalling.socket.io';
import { CallSession } from 'src/models/CallSession.model';
import { ValueCallback } from 'src/types/common.type';

import { getOtherUserId } from '../../../../utils/call-session.utils';

export const createPeerConnection = (
  callSession: CallSession,
  selfUserId: number,
  iceServer: RTCIceServer[],
  onRemoteStream: ValueCallback<MediaStream>,
  onDataChannelEvent: ValueCallback<string>,
) => {
  const otherUserId = getOtherUserId(callSession, selfUserId);

  const getSignallingData = (): BaseSignallingDto => ({
    fromUserId: selfUserId,
    toUserId: otherUserId,
  });

  // todo : Remove these URL's once the implementation is done , later we will integrate TWILIO_API_URL

  const peerConnection = new RTCPeerConnection({
    iceServers: iceServer,
  });

  const dataChannel = peerConnection.createDataChannel('dataChannels');

  const handleSignallingEvents = () => {
    const handlePeerIceCandidate: CallIceCandidateHandler = ({
      candidate,
      fromUserId,
    }) => {
      if (fromUserId !== otherUserId) {
        return;
      }
      if (peerConnection.signalingState === 'stable') {
        peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    };

    onCallIceCandidate(handlePeerIceCandidate);

    const handlePeerCallAnswer: CallAnswerHandler = ({ sdp, fromUserId }) => {
      if (fromUserId !== otherUserId) {
        return;
      }
      if (peerConnection.signalingState !== 'stable') {
        peerConnection.setRemoteDescription(sdp);
      }
    };
    onCallAnswer(handlePeerCallAnswer);

    const handlePeerCallOffer: CallOfferHandler = async ({
      sdp,
      fromUserId,
    }) => {
      if (fromUserId !== otherUserId) {
        return;
      }
      await peerConnection.setRemoteDescription(sdp);
      const answer = await peerConnection.createAnswer();
      if (peerConnection.signalingState !== 'stable') {
        await peerConnection.setLocalDescription(answer);
      }
      emitCallAnswer({
        ...getSignallingData(),
        sdp: answer,
      });
    };
    onCallOffer(handlePeerCallOffer);

    const removeCallSignallingEventListeners = () => {
      unsubscribeCallIceCandidate(handlePeerIceCandidate);
      unsubscribeCallAnswer(handlePeerCallAnswer);
      unsubscribeCallOffer(handlePeerCallOffer);
    };

    return removeCallSignallingEventListeners;
  };
  const cleanupSignallingEvents = handleSignallingEvents();

  const handleLocalPeerConnectionEvents = () => {
    const handleLocalTrack = (ev: RTCTrackEvent) => {
      const stream = _.head(ev.streams);

      if (stream) {
        onRemoteStream(stream);
      }
    };
    peerConnection.addEventListener('track', handleLocalTrack);

    peerConnection.ondatachannel = event => {
      const { channel: dataChannel } = event;
      dataChannel.onmessage = event => {
        const { data } = event;
        onDataChannelEvent(data);
      };
    };
    // }
    const handleLocalIceCandidate = ({
      candidate,
    }: RTCPeerConnectionIceEvent) => {
      if (candidate) {
        emitCallIceCandidate({
          ...getSignallingData(),
          candidate,
        });
      }
    };
    peerConnection.addEventListener('icecandidate', handleLocalIceCandidate);

    const handleNegotiationNeeded = () => {
      if (peerConnection.signalingState === 'stable') {
        sendOffer();
      }
    };
    peerConnection.addEventListener(
      'negotiationneeded',
      handleNegotiationNeeded,
    );

    const removePeerConnectionEventListeners = () => {
      peerConnection.removeEventListener(
        'icecandidate',
        handleLocalIceCandidate,
      );
      peerConnection.removeEventListener(
        'negotiationneeded',
        handleNegotiationNeeded,
      );
      peerConnection.removeEventListener('track', handleLocalTrack);
    };

    return removePeerConnectionEventListeners;
  };
  const cleanupLocalPeerConnectionEvents = handleLocalPeerConnectionEvents();

  const sendOffer = async () => {
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    emitCallOffer({
      ...getSignallingData(),
      sdp: offer,
    });
  };

  const cleanup = () => {
    cleanupSignallingEvents();
    cleanupLocalPeerConnectionEvents();
    dataChannel.close();
    peerConnection.close();
    dataChannel.onmessage = null;
    dataChannel.onopen = null;
    dataChannel.onclose = null;
    peerConnection.ondatachannel = null;
  };

  return { peerConnection, cleanup, dataChannel };
};
