import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import {
  acceptCallSession,
  acknowledgeCallSession,
  rejectCallSession,
} from 'src/apis/call-sessions.api';
import { getCall } from 'src/apis/calls.api';
import {
  CallSessionHandler,
  subscribeCallSessionDisconnected,
  subscribeIncomingCall,
  unsubscribeCallSessionDisconnected,
  unsubscribeIncomingCall,
} from 'src/apis/socket.io/call-sessions.socket.io';
import { getUserDetails } from 'src/apis/users.api';
import useSound from 'src/components/NotificationSound/useSound';
import { CallSession } from 'src/models/CallSession.model';

import {
  buildCallPageRoute,
  buildGroupCallPageRoute,
  isCallPageRoute,
  isGroupCallPageRoute,
} from 'src/routes/routeList';
import { getUserDisplayName } from 'src/utils/user.utils';

import IncomingCallAlert from './components/incoming-call';
import IncomingGroupCallAlert from './components/incoming-call/components/incomingGroupCallAlert';
import { incomingCallNotificationDuration } from './constants/incoming-call-notification-duration.constant';

import callRingtone from '../../../assets/call-ringtone/call-ringtone.mp3';

const useCallNotifications = () => {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const acknowledgeCallMutation = useMutation(acknowledgeCallSession);
  const acceptCallMutation = useMutation(acceptCallSession);
  const rejectCallMutation = useMutation(rejectCallSession);
  const getCallDetailsMutation = useMutation(getCall);

  const [play, { stop }] = useSound(callRingtone, {
    volume: 0.5,
    soundEnabled: true,
    playbackRate: 1,
    loop: true,
    autoplay: false,
  });

  const handleIncomingCall = useCallback(
    async (callSession: CallSession) => {
      const toastId = `call-notification-toast`;
      if (toast.isActive(toastId)) {
        return;
      }

      // fetch call by callSession.CallId details to check whether the call is group call or not
      const call = await getCallDetailsMutation.mutateAsync(callSession.CallId);

      const caller = await getUserDetails(callSession.CallerId.toString());
      const callerName = caller ? getUserDisplayName(caller) : 'Unknown User';

      // Acknowledge that we have received the call. The caller will get to know that we are not unreachable.
      acknowledgeCallMutation.mutate(callSession.id);

      // check if user is on another call either is personal call or group call so in that case call notification is not visible to that user.
      const isUserInCall =
        isCallPageRoute(location.pathname) ||
        isGroupCallPageRoute(location.pathname);
      if (isUserInCall) {
        return;
      }

      const acceptedCall = () => {
        stop();
        onAcceptCall();
      };

      const rejectedCall = () => {
        stop();
        onRejectCall();
      };

      const onAcceptCall = () => {
        if (call && call.isGroupCall) {
          acceptCallMutation.mutate(callSession.id);
          toast.close(toastId);
          history.push(buildGroupCallPageRoute(callSession.CallId.toString()));
        } else if (call && !call.isGroupCall) {
          acceptCallMutation.mutate(callSession.id);
          toast.close(toastId);
          history.push(buildCallPageRoute(callSession.CallId.toString()));
        }
        return;
      };

      const onRejectCall = () => {
        rejectCallMutation.mutate(callSession.id);
        toast.close(toastId);
      };

      const handleSessionExpired: CallSessionHandler = expiredSession => {
        if (expiredSession.id === callSession.id) {
          toast.close(toastId);
        }
      };

      subscribeCallSessionDisconnected(handleSessionExpired);

      toast({
        id: toastId,
        position: 'top-left',
        duration: incomingCallNotificationDuration,
        onCloseComplete: () =>
          unsubscribeCallSessionDisconnected(handleSessionExpired),
        render: () => {
          if (call && call.isGroupCall) {
            return (
              <IncomingGroupCallAlert
                groupImageProps={{
                  name: call?.messageGroup.name,
                  src: call?.messageGroup?.imageUrl,
                }}
                groupName={call?.messageGroup.name}
                onAcceptCall={acceptedCall}
                onRejectCall={rejectedCall}
                sound={play}
                stopSound={stop}
              />
            );
          } else {
            return (
              <IncomingCallAlert
                avatarProps={{
                  name: callerName,
                  src: caller?.avatarUrl,
                }}
                callerName={callerName}
                onAcceptCall={acceptedCall}
                onRejectCall={rejectedCall}
                sound={play}
                stopSound={stop}
              />
            );
          }
        },
      });
    },
    [
      location,
      toast,
      history,
      getCallDetailsMutation,
      acknowledgeCallMutation,
      acceptCallMutation,
      rejectCallMutation,
      play,
      stop,
    ],
  );

  useEffect(() => {
    subscribeIncomingCall(handleIncomingCall);

    return () => unsubscribeIncomingCall(handleIncomingCall);
  }, [handleIncomingCall]);
};

export default useCallNotifications;
