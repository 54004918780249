import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import useCurrentUser from 'src/hooks/useCurrentUser';
import { CallSession } from 'src/models/CallSession.model';

import useCallSessions from '../../../hooks/useCallSessions';
import {
    PeerConnectionContext,
    SessionPeerConnectionMap,
    SessionPeerStreamMap,
    SessionPeerVideoStatus,
} from '../peer-connection.context';
import { createPeerConnection } from './utils/create-peer-connection.util';
import useIceServers from '../hooks/useIceServers';

export enum DataChannelsEventsMessage {
    enableVideo = 'video is enabled',
    disableVideo = 'video is disabled',
    enableMic = 'mic is enabled',
    disableMic = 'mic is disabled',
}
const PeerConnectionProvider: React.FC = ({ children }) => {
    const [currentUser] = useCurrentUser();
    const { callSessions } = useCallSessions();
    const iceServer = useIceServers();
    const [peerConnections, setPeerConnections] =
        useState<SessionPeerConnectionMap>({});
    const [peerStreams, setPeerStreams] = useState<SessionPeerStreamMap>({});
    const [peerConnCleanups, setPeerConnCleanups] = useState<Function[]>([]);
    const [peerConnDataChannels, setPeerConnDataChannels] = useState<
        RTCDataChannel[]
    >([]);
    const [peerVideoStatus, setPeerVideoStatus] =
        useState<SessionPeerVideoStatus>({});

    useEffect(() => {
        if (!currentUser) {
            return;
        } else if (iceServer) {
            setPeerConnections(prevPeerConns =>
                _.reduce<CallSession, SessionPeerConnectionMap>(
                    callSessions,
                    (peerConnAcc, session) => {
                        // if peer connection already exists, skip
                        if (peerConnAcc[session.id]) {
                            return peerConnAcc;
                        }

                        // Don't create peer connection if session is not active (other user has not joined)
                        if (!session.joinedAt) {
                            return peerConnAcc;
                        }

                        const { peerConnection, cleanup, dataChannel } =
                            createPeerConnection(
                                session,
                                currentUser.id,
                                iceServer,

                                stream => {
                                    setPeerStreams(prev => ({
                                        ...prev,
                                        [session.id]: stream,
                                    }));
                                },

                                dc => {
                                    if (
                                        dc ===
                                        DataChannelsEventsMessage.enableVideo
                                    ) {
                                        setPeerVideoStatus(prev => ({
                                            ...prev,
                                            [session.id]: true,
                                        }));
                                    } else if (
                                        dc ===
                                        DataChannelsEventsMessage.disableVideo
                                    ) {
                                        setPeerVideoStatus(prev => ({
                                            ...prev,
                                            [session.id]: false,
                                        }));
                                    }
                                },
                            );
                        setPeerConnCleanups(prevSubs => [...prevSubs, cleanup]);
                        setPeerConnDataChannels(prevDataChannels => [
                            ...prevDataChannels,
                            dataChannel,
                        ]);
                        peerConnAcc[session.id] = peerConnection;
                        return peerConnAcc;
                    },
                    prevPeerConns,
                ),
            );
        }
    }, [callSessions, currentUser, iceServer]);

    const addStream = useCallback(
        (stream: MediaStream) => {
            // for all sessions, get peerConnection and add track
            _.values(peerConnections).forEach(peerConnection => {
                stream
                    .getTracks()
                    .forEach(track => peerConnection.addTrack(track, stream));
            });
        },
        [peerConnections],
    );

    const addTrack = useCallback(
        (track: MediaStreamTrack, stream: MediaStream) => {
            // for all sessions, get peerConnection and add track
            _.values(peerConnections).forEach(peerConnection =>
                peerConnection.addTrack(track, stream),
            );
        },
        [peerConnections],
    );

    const mutePeerStream = useCallback(
        (callSessionId: keyof SessionPeerStreamMap) => {
            const peerStream = peerStreams[callSessionId];
            if (!peerStream) {
                return;
            }

            peerStream
                .getAudioTracks()
                .forEach(track => (track.enabled = false));
        },
        [peerStreams],
    );

    const unmutePeerStream = useCallback(
        (callSessionId: keyof SessionPeerStreamMap) => {
            const peerStream = peerStreams[callSessionId];
            if (!peerStream) {
                return;
            }

            peerStream
                .getAudioTracks()
                .forEach(track => (track.enabled = true));
        },
        [peerStreams],
    );

    const notifyEnableVideo = () => {
        peerConnDataChannels.forEach(channel => {
                if (channel.readyState === 'open') {
                return channel.send(DataChannelsEventsMessage.enableVideo);
            }
            });
    };

    const notifyDisableVideo = () => {
        peerConnDataChannels.forEach(channel => {
                if (channel.readyState === 'open') {
                return channel.send(DataChannelsEventsMessage.disableVideo);
            }
            });
    };

    const notifyEnableMic = () => {
        peerConnDataChannels.forEach(channel => {
                if (channel.readyState === 'open') {
                return channel.send(DataChannelsEventsMessage.enableMic);
            }
            });
    };

    const notifyDisableMic = () => {
        peerConnDataChannels.forEach(channel => {
                if (channel.readyState === 'open') {
                return channel.send(DataChannelsEventsMessage.disableMic);
            }
            });
    };

    return (
        <PeerConnectionContext.Provider
            value={{
                peerConnections,
                peerStreams,
                peerVideoStatus,
                addStream,
                addTrack,
                unmutePeerStream,
                mutePeerStream,
                notifyEnableVideo,
                notifyDisableVideo,
                notifyEnableMic,
                notifyDisableMic,
                peerConnCleanups,
            }}
        >
            {children}
        </PeerConnectionContext.Provider>
    );
};

export default PeerConnectionProvider;